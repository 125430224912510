<template>
  <v-container>
    <v-row class="mb-5">
      <h2>Reactions of Copper - Results</h2>
    </v-row>
    <v-row>
      <p class="mb-3">
        Fill in the table below using the data you collected throughout the experiment and any
        required calculations.
      </p>
    </v-row>

    <v-row class="mb-4">
      <v-simple-table>
        <thead>
          <tr>
            <td>
              <stemble-latex content="$\text{Property}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Value}$" />
            </td>
          </tr>
          <tr>
            <td>a) Initial mass of Cu (g)</td>
            <td>
              <calculation-input
                v-model="inputs.massCuInit"
                class="centered-input my-1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>b) Mass of evaporating dish (g)</td>
            <td>
              <calculation-input
                v-model="inputs.massEvapDish"
                class="centered-input my-1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>c) Mass of evaporating dish + Cu (g)</td>
            <td>
              <calculation-input
                v-model="inputs.massEvapDishCu"
                class="centered-input my-1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>d) Final mass of Cu (g)</td>
            <td>
              <calculation-input
                v-model="inputs.massCuFinal"
                class="centered-input my-1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>e) Theoretical Yield (g)</td>
            <td>
              <calculation-input
                v-model="inputs.theoYield"
                class="centered-input my-1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>f) Percent Yield (%)</td>
            <td>
              <calculation-input
                v-model="inputs.pctYield"
                class="centered-input my-1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-row>
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';

export default {
  name: 'OleMissL8ReportSheet',
  components: {
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        massCuInit: null,
        massEvapDish: null,
        massEvapDishCu: null,
        massCuFinal: null,
        theoYield: null,
        pctYield: null,
      },
    };
  },
};
</script>

<style>
input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
